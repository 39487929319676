@import '../../../node_modules/@agile/react-components/src/scss/variables';
$title-color: #000037;

.print-price-page {
  color: #1b1f2b;
  font-size: 13px;
  line-height: 16px; // Fix first letter vertical position in Chrome https://github.com/twbs/bootstrap/issues/21771

  ::first-letter {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: underline;
  }

  .table {
    thead {
      th {
        border-bottom: 1px solid rgba(#1b1f2b, 0.2);
        border-top: 0;
      }
    }

    td {
      border-top: 1px solid rgba(#757679, 0.2);
    }
  }

  h2,
  h3 {
    color: $title-color;
    font-size: 16px;
    font-weight: 900;
    font-family: $font-family-secondary;
    margin: 0 0 12px;
  }

  h4 {
    color: $title-color;
    font-size: 12px;
    font-weight: 900;
    font-family: $font-family-secondary;
  }

  .text-bolder {
    color: #1b1f2b;
  }

  .text-lighter {
    opacity: 0.6;
    color: #1b1f2b;
  }

  .underlying-img {
    max-width: 100%;
    max-height: 200px;
  }

  .bg-gray {
    // background-image: linear-gradient(#f4f5f6, #f4f5f6);
    background-color: rgba(#f4f5f6, 0.7);
  }

  .recharts-responsive-container {
    svg {
      margin-left: -10px;
    }
  }

  .bg-blue {
    a {
      color: $white;
    }

    background: {
      color: #17375e;
    }
  }

  .structure-description {
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 13px;

    p {
      margin-bottom: 0.7rem;
    }

    .markdown-blockquote {
      display: block;
      border: 0;
      margin: 0 0 0.7rem;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        background-color: rgba(227, 228, 230, 1);
      }
    }
  }

  .section-image {
    max-width: 258px;
  }

  .dl-horizontal {
    margin-bottom: 5px;
    line-height: 21px;
    font-size: 13px;
  }

  .table-message {
    font-size: 11px;
  }

  .structure-description-section {
    padding: 21px 24px;
    margin-bottom: 0.5rem;
  }

  .product-chart-tooltip {
    background-color: $white;
    border: 1px solid #cccccc;
  }

  .recharts-layer {
    &.recharts-cartesian-axis-tick {
      tspan {
        fill: #000000;
      }
    }
  }

  .text-sm {
    font-size: 75%;
  }

  .accumulation-mechanism-table-wrapper {
    padding: 0 10px 0 42px;
    position: relative;
    min-height: 170px;

    .title {
      position: absolute;
      top: 8px;
      transform: rotate(270deg);
      transform-origin: right top;
      right: 100%;
      margin: 10px -7px;
      white-space: nowrap;
    }
  }

  .accumulation-mechanism-table {
    color: #1b1f2b;
    width: 100%;

    &.table {
      thead {
        font-size: 11px;

        th {
          vertical-align: middle;
        }
      }

      margin: 0;

      th,
      td {
        text-align: center;
        padding: 4px;
        line-height: 1.1;
        font-weight: 600;
      }
    }
  }

  .section-refernce-figure {
    font-size: 13px;
    line-height: 16px;
  }

  .section-questions {
    margin: 25px 0 20px;
    font-size: 14px;
    color: rgba($white, 0.8);
    max-width: 312px;
    max-height: 176px;

    p {
      color: rgba($white, 0.8);
      margin: 0 0 24px;
    }

    h3 {
      font-size: 16px;
      margin: 0 0 5px;
      line-height: 1;
    }
  }
}
