.loading {
  .Select-noresults {
    visibility: hidden;
  }

  .Select-noresults:before {
    content: "Loading...";
    visibility: visible;
  }
}
