@import '../../../node_modules/@agile/react-components/src/scss/variables';

.print-modal {
  &.modal-dialog {
    max-width: none;
  }
}

.print-layout {
  font-family: $font-family-base;
  width: 800px;
  margin: 0 auto;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;

  -webkit-print-color-adjust: exact; // Fix bg image and colors for Chrome

  background: {
    color: $white;
    image: url('./bg_pdf@2x.jpg');
    position: top center;
    size: 100% auto;
    repeat: no-repeat;
  }

  &>.header {
    height: 136px;
    margin: 0 0 8px;
    overflow: hidden;
    padding: 28px 24px;

    .main-logo {
      max-width: 236px;
    }

    .second-logo {
      width: 25px;
      height: 25px;
      border: 1px solid white;

      &-text {
        font-size: 8px;
        margin: 3px 0;
      }
    }

    .header-title {
      font-size: 16px;
      font-weight: 900;
      line-height: 1.5;
      text-align: left;
      color: $white;
      text-transform: uppercase;
      font-family: $font-family-secondary;
    }
  }

  &>.content {
    color: black;
  }

  .recharts-responsive-container {
    margin: 0 0 4px;
  }

  .recharts-legend-item-text {
    margin-right: 30px;
  }

  &>.footer {
    color: black;
    padding: 4px 0 0;

    .title {
      font-size: 12px;
      line-height: 1;
      font-weight: 600;
      margin: 4px 0;
      color: #1b1f2b;
    }

    .small-logo {
      width: 3.25cm;
    }

    .disclaimer {
      border-top: solid 1px $gray_light;
      color: #1b1f2b;
      padding: 3px 24px;
      line-height: 1.1;
      opacity: 0.6;
      text-align: justify;

      font: {
        size: 6px;
      }
    }
  }
}

@media print {
  .print-layout {
    box-shadow: none;
    border: none;
    width: 8.27in;
  }

  .modal-open #root {
    display: none;
  }

  .print-modal .modal-content {
    background: $white;
  }

  .text-sm {
    font: {
      size: 6pt;
    }
  }

  h2 {
    font-size: 16pt;
  }

  h3 {
    font-size: 16pt;
  }

  body {
    font-size: 9.5pt;
  }
}
