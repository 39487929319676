@import '../../../node_modules/@agile/react-components/src/scss/variables';

.nonloged {
  background-color: $gray-600;
  min-height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  width: 236px;
  height: auto;
  transition: opacity 0.15s ease-in-out;
  background-size: 100%;
  position: relative;
  z-index: $video-zindex + 3;
  display: block;
  margin: 117px 0 43px;

  @include media-breakpoint-up(sm) {
    margin: 50px 0 0;
    width: 266px;
  }
}

.nonloged-container {
  flex: 1 1 0%;
  min-height: 100vh;
  position: relative;
  z-index: $video-zindex + 2;
}

.nonloged-background {
  background-color: black;
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  right: 0;
}

.logged-layout {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding: #{$header-h-logged + 34px} 0 0;
  background-size: cover;

  @include media-breakpoint-up(sm) {
    padding: #{$header-h-logged + 32px} 0 0;
  }

  &:after {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    width: 100%;
    height: 100%;
    content: '';
    z-index: -1;
    left: 0;
    top: 0;
    position: absolute;
    pointer-events: none;
  }

  .main-content {
    flex: 1 1 0;

    background: {
      size: contain;
      repeat: no-repeat;
      position-y: $header-h-logged;
      position-x: center;
      attachment: fixed;
    }
  }
}

.alert-container {
  position: fixed;
  top: 0;
  right: 50%;
  transform: translateX(50%) translateY(50%);
  z-index: $z-index-alerts;
}

.bg-white {
  $color: #959595;
  background-color: white;

  .navbar-nav {

    .nav,
    .nav-link {
      color: $color;

      &:hover,
      &.active {
        color: darken($color, 20);
      }
    }
  }
}

.bottom-navbar {
  $from: #24cbce;
  $to: #23abe5;
  background: $to;
  /* Old browsers */
  background: linear-gradient(to bottom, $from 0%, $to 100%);

  .navbar-nav {
    .nav-item {
      border-right: solid rgba(128, 128, 128, 0.5) 1px;
      padding: $navbar-padding-y/4 $navbar-padding-x; // padding-left: $navbar-padding-x;

      // padding-right: $navbar-padding-x;
      // padding-top: $navbar-padding-y/4;
      // padding-bottom: $navbar-padding-y/4;
      .nav-link {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }

    .nav-item:first-child {
      padding-left: 0;
    }

    .nav-item:last-child {
      padding-right: 0;
      border-right: none;
    }
  }

  .nav-link {
    text-transform: uppercase;
    color: white;
  }
}

$control-invalid-color: #f46a6b;
$control-invalid-feedback-color: #efa4a5;
$control-padding-x: 24px;
$control-padding-y: 16px;

.form {
  width: 100%;
  border-radius: 4px;
  background-color: rgba($white, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 16px 32px;
  margin: 20px auto 70px;
  font-weight: 500;

  @include media-breakpoint-up(sm) {
    padding: 28px 24px 26px;
    width: 464px;
  }

  &-control-label {
    font-size: 12px;
    letter-spacing: -0.2px;
    color: rgba($white, 0.8);
  }

  &-login {
    .form-group {
      label {
        margin: 0;
        padding: 0;
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 33px;
    letter-spacing: 0.04em;

    @include media-breakpoint-up(sm) {
      margin: 0 0 28px;
    }
  }

  .btn {
    letter-spacing: 0.08em;
  }

  &-message {
    font-size: 14px;
    color: rgba($white, 1);
    margin: 43px 0 38px;
    font-weight: 500;
    padding: 0 40px;
    font-weight: 400;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }

  &-sub-message {
    font-size: 13px;
    color: rgba($white, 0.8);
  }

  .form-footer {
    a {
      letter-spacing: 0.08em;
    }
  }

  .form-control {
    height: 48px;
    border-radius: 4px;
    background-color: rgba($white, 0.2);
    border: solid 1px rgba($white, 0.6);
    font-size: 14px;
    color: $white;
    padding: $control-padding-y $control-padding-x*1.4 $control-padding-y $control-padding-x;
    box-shadow: none;
    letter-spacing: 0.08em;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
      background-color: transparent;
    }

    &::placeholder {
      opacity: 0.8;
      color: inherit;
    }

    &.is-invalid {
      border-color: $control-invalid-color;
    }
  }

  .form-group {
    margin-bottom: 32px;
    position: relative;

    .invalid-feedback {
      position: absolute;
      color: $control-invalid-feedback-color;
      padding: 0 $control-padding-x;
      font-size: 12px;
      letter-spacing: 0.4px;
    }

    .form-feedback-icon {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      margin: 11px 16px;
      font-size: 18px;
      line-height: 1;
      color: $control-invalid-feedback-color;
    }
  }

  .btn-primary {
    height: 48px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.03em;
    text-align: center;
    color: $white;
    margin: 13px 0 24px;

    @include media-breakpoint-up(sm) {
      margin: 8px 0 50px;
    }
  }

  .link,
  a {
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: $white;
    position: relative;
    padding: 0 0 3px;
    transition: opacity 0.15s ease-in-out;
    text-decoration: none;

    &:hover {
      opacity: 0.9;
      color: $white;
      text-decoration: none;

      &::after {
        transform: scale(1, .3);
      }
    }

    &::after {
      content: '';
      background-color: $white;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      transition: transform 0.2s ease-in-out;
      position: absolute;
    }
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

// Agile theme
.agile {
  .logged-layout {
    background: {
      image: url('../../../assets/images/bg_main.jpg');
      position: top center;
      repeat: no-repeat;
      size: cover;
      attachment: fixed;
    }
  }
}
