@import '../../../node_modules/@agile/react-components/src/scss/variables';
$print-navbar-height: 3.5rem;
.print-modal {
  &-navbar {
    @media pint {
      & {
        display: none;
      }
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $print-navbar-height;
    z-index: 2031;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
    .btn-icon {
      width: 13px;
      height: 13px;
    }
  }
  width: auto;
  max-width: 100%;
  .modal-content {
    background: transparent;
    border: none;
    .modal-body {
      margin-top: $print-navbar-height;
      padding: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  &-backdrop {
    &.show {
      opacity: 0.6 !important;
    }
  }
}

.w-104 {
  width: 104px;
}

@media print {
  .print-modal {
    background-color: white;
    margin: 0;
    padding: 0;

    &-navbar {
      display: none
    }
    .modal-content {
      .modal-body {
        margin: 0;
      }
    }
  }
}
