@font-face {
  font-family: 'SFUIDisplay';
  src: url('../fonts/SFUIDisplay/SFUIDisplayRegular.eot');
  src: url('../fonts/SFUIDisplay/SFUIDisplayRegular.eot') format('embedded-opentype'),
  url('../fonts/SFUIDisplay/SFUIDisplayRegular.woff2') format('woff2'),
  url('../fonts/SFUIDisplay/SFUIDisplayRegular.woff') format('woff'),
  url('../fonts/SFUIDisplay/SFUIDisplayRegular.ttf') format('truetype'),
  url('../fonts/SFUIDisplay/SFUIDisplayRegular.svg#SFUIDisplayRegular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay';
  src: url('../fonts/SFUIDisplay/SFUIDisplaySemibold.eot');
  src: url('../fonts/SFUIDisplay/SFUIDisplaySemibold.eot') format('embedded-opentype'),
  url('../fonts/SFUIDisplay/SFUIDisplaySemibold.woff2') format('woff2'),
  url('../fonts/SFUIDisplay/SFUIDisplaySemibold.woff') format('woff'),
  url('../fonts/SFUIDisplay/SFUIDisplaySemibold.ttf') format('truetype'),
  url('../fonts/SFUIDisplay/SFUIDisplaySemibold.svg#SFUIDisplaySemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
