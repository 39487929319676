@media print {
  .modal-open {
    .page-pricing {
      .logged-layout {
        display: none !important;
      }
    }
  }

  .modal-open .modal {
    background-color: $white;
  }

  .logged-layout {
    background: $white;

    .main-content {
      margin: 0 !important;
      padding: 0 !important;
      display: block;
    }
  }

  .logged-header {
    display: none !important;
  }

  .navbar {
    display: none;
  }
}
