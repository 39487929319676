.markdown {
    &-blockquote {
        position: relative;
        margin: 0 0 1rem;
        padding-left: 16px;

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 2px;
            background: rgba(#e3e4e6, 0.3);
            content: '';
        }
    }
}
