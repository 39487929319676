@import '../../node_modules/@agile/react-components/src/scss/variables';
.notification {
  &-wrapper {
    position: relative;
  }
  &-counter {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px 11px;
    min-width: 16px;
    height: 16px;
    background: #f3294b;
    color: $white;
    padding: 2px;
    border: solid 1px #3381c0;
    border-radius: 8px;
    text-align: center;

    span {
      text-align: center;
      vertical-align: top;
      display: inline-block;
      font-size: 10px;
      line-height: 10px;
    }
  }
  &-dropdown {
    width: 320px;
    border-radius: 1px;
    background-color: rgba(29, 37, 46, 0.97);
    box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    font-size: 14px;
    margin: 0 0 0 -26px;
    @include media-breakpoint-up(sm) {
      width: 424px;
    }
    .dropdown-arrow {
      margin: 0 -6px;
      left: auto;
    }
    &-header {
      padding: 10px 24px;
      .nav-item {
        padding: 3px 0;
        display: inline-block;
      }
      .read-all-btn {
        cursor: pointer;
        float: left;
        line-height: 38px;
      }
      .show-only-read-btn {
        float: right;
      }
      .read-all-disable {
        opacity: 0.3;
        cursor: default;
      }
    }
    &-content {
      padding: 0 15px;
      max-height: 420px;
      overflow: auto;
      @include media-breakpoint-up(sm) {
        max-height: 528px;
      }
    }
    &-row {
      padding-bottom: 7px;
      padding-top: 8px;
      position: relative;
      border-bottom: 1px solid transparent;

      &.notify_me {
        cursor: pointer;
      }
      &:after {
        content: "";
        left: 65px;
        right: 0;
        border-bottom: 1px solid rgba($white, 0.3);
        position: absolute;
        height: 0px;
        bottom: 0;
      }
      .notification-content {
        line-height: 28px;
      }
      .notification-date {
        font-size: 11px;
      }
      &.unread {
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        .notification-icon {
          background-color: $gray_light;
          color: #0bb2dd;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

.logged-header .navbar-nav {
  .notification-dropdown {
    -webkit-font-smoothing: subpixel-antialiased;
  }
  .notification-dropdown-icon {
    .nav-link {
      padding: 14px 15px;
    }
  }
}

.app-table {
  .notification-content-switcher {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-color: rgba($white, 0.1);
    display: inline-block;
    list-style: 24px;
    vertical-align: middle;
    padding: 3px;
    @include media-breakpoint-down(sm) {
      background-color: rgba($black, 0.86);
    }
    transition: {
      property: background;
      duration: 0.15s;
      timing-function: ease-in-out;
    }
    &:hover {
      background-color: rgba($white, 0.2);
    }
    svg {
      width: 18px;
      vertical-align: top;
      display: inline-block;
      height: 18px;
    }
  }
}

.icon-info {
  opacity: 0.4;
  margin: 0 0 0 6px;
}

.notification-modal-wrapper {
  .modal-switcher {
    margin: 2px;
  }
}
