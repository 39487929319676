@import '../../node_modules/@agile/react-components/src/scss/variables';

.transition-opacity {
  &-enter {
    opacity: 0.01;

    &-active {
      opacity: 1;
      transition: opacity .5s ease-in;
    }
  }

  &-leave {
    opacity: 1;

    &-active {
      opacity: 0.01;
      transition: opacity .5s ease-out;
    }
  }
}

.transition-fade-jump {
  transition: {
    property: opacity, transform;
    duration: 0.4s;
    timing-function: ease-in-out;
  }

  opacity: 0;
  transform: translate3d(0, 20%, 0);

  &-entering {
    opacity: 0;
  }

  &-entered {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.transition-appear-up {

  &-enter,
  &-appear {
    transition: transform ease-in-out 180ms, opacity ease-in-out 180ms;
    transform: translate3d(0, 40px, 0);
    opacity: 0;

    &-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &-exit,
  &-leave {
    transform: translate3d(0, 0, 0);

    &-active {
      transition: transform ease-in-out 180ms;
      transform: translate3d(0, 40px, 0);
    }
  }
}

.transition-appear-scale {

  &-enter,
  &-appear {
    transition: transform ease-in-out 500ms, opacity ease-in-out 500ms;
    transform: scale(1.25);
    opacity: 0.8;

    &-active {
      opacity: 1;
      transform: scale(1);
    }
  }

  &-exit,
  &-leave {
    transform: scale(1.25);

    &-active {
      transition: transform ease-in-out 500ms;
      transform: scale(1);
    }
  }
}

.fade-blocks {
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  opacity: 0;

  &.in {
    visibility: visible;
    opacity: 1;
  }
}

//Modal animations
.modal {
  &.fade .modal-dialog {
    transition: $modal-transition;
    transform: translate(0, 100px);
    opacity: 0;
  }

  &.show .modal-dialog {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.transition-appear-fade-up-enter {
  z-index: 11;
  transform: translate(0, 20px);
  opacity: 0.01;
}

.transition-appear-fade-up-enter-active {
  z-index: 11;
  transform: translate(0, 0);
  opacity: 1;
  transition: transform, opacity 180ms ease-in;
}

.transition-appear-fade-up-exit {
  z-index: 11;
  transform: translate(0, 0);
  opacity: 1;
}

.transition-appear-fade-up-exit-active {
  z-index: 11;
  transform: translate(0, 20px);
  opacity: 0.01;
  transition: transform, opacity 180ms ease-in;
}

.transition-appear-element {

  &-enter,
  &-appear {
    transition: transform ease-in-out 350ms, opacity ease-in-out 350ms, border ease-in-out 350ms;
    transform: translateY(-8%) scale(1.02);
    opacity: 0;
    border-bottom-color: $primary;

    &-active {
      transform: translateY(0) scale(1);
      opacity: 1;
      border-bottom-color: transparent;
    }
  }
}

.collapsing,
.collapse {
  transition: all 0.35s ease-in-out;
  overflow: hidden;
}
