@import '../../node_modules/@agile/react-components/src/scss/variables';
@import "../../node_modules/@agile/react-components/src/scss/bootstrap";
@import '../../../node_modules/react-joyride/lib/react-joyride-compiled.css';

dl {
  dt {
    font-weight: normal;
    color: rgba($white, 0.8);
    font-size: 12px;
  }
}

.text-buy {
  color: $buy
}

.order-error {
  color: $buy;
  font-size: 11px;
  text-align: right;
}

.nav-link {
  font-weight: 300;
}

//@TODO: hide icon in Price Form
.form-feedback-icon {
  display: none;
}

.form-control {
  box-shadow: none;
  &:hover,
  &:focus {
    box-shadow: none !important; //@TODO: remove important
  }
}

.font-sm {
  font-size: $font-size-sm;
}

.font-xs {
  font-size: $font-size-xs;
}

.block-text {
  padding: $input-btn-padding-x-lg $input-btn-padding-x-lg;
  background-color: $input-bg-color;
  color: $input-color;
}

@import "../../../node_modules/react-select/scss/select.scss";
@import "../../../node_modules/react-select/scss/default.scss";
@import "../../../node_modules/react-virtualized-select/styles.css";

.is-open > .Select-control {
  background-color: $input-bg-focus;
}
.Select {

  .Select-arrow-zone {
    transition: opacity 0.15s ease-out, transform 0.15s linear;
    opacity: 1;
  }

  &.is-disabled {
    .Select-arrow-zone {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.flex-1 {
  flex: 1 auto 0;
}

.modal-backdrop {
  &.show {
    opacity: 0.6;
  }
}

.page {
  overflow: hidden;
}

.react-datepicker-popper {
  z-index: 1056 !important;
}

.trade-modal-legs{
  li>span {
    font-variant-numeric: tabular-nums;
  }
}

@import 'uikit';
@import 'animations';

