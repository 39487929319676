$countdown_h: 14px;

.countdown {
  font-size: $countdown_h;
  line-height: $countdown_h;
  pointer-events: none;

  .progress-bar:after {
    display: none;
  }
}

.countdown-reference {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;

  .progress-bar:after {
    display: none;
  }
}
