@import '../../../../node_modules/@agile/react-components/src/scss/variables';
@import '../../../../../node_modules/bootstrap-daterangepicker/daterangepicker.css';

.react-bootstrap-daterangepicker-container {
  .form-control {
    min-width: 198px;
    line-height: 1.3;
  }
}

.daterangepicker-wrapper {
  position: relative;
  width: 100%;
  label {
    display: block;
  }
  .form-control {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
  .react-bootstrap-daterangepicker-container {
    display: block !important;
    @include media-breakpoint-up(sm) {
      display: inline-block !important;
    }
  }
  .form-control-placeholder {
    margin: 6px 0;
    color: rgba($white, 0.6);
    display: inline-block;
    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    width: auto;
    display: block;
  }
}

.daterangepicker {
  background-color: $select-bg;
  box-shadow: 0 3px 5px 0 rgba($black, 0.3);
  border: 0;
  .calendar-table {
    background-color: $select-bg;
    border: 0;
    .next span,
    .prev span {
      border-color: $white;
    }
  }

  &:before,
  &:after {
    border-bottom-color: $select-bg;
  }

  td {
    &.available {
      &:hover {
        background-color: $select-bg;
        span {
          border-color: $white;
        }
      }
    }
  }

  .drp-buttons {
    border: 0;
    padding-bottom: 10px;
  }

  td {
    &.available {
      &:hover {
        background-color: $select-option-focused-bg;
      }
    }
    &.in-range {
      background-color: $select-option-selected-bg;
      color: $white;
      &.active {
        background-color: #357ebd;
      }
    }

    &.off,
    &.off.in-range,
    &.off.start-date,
    &.off.end-date {
      background-color: $select-bg;
    }
  }
}
