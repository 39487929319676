.loader-wrapper {
  position: relative; }
  .loader-wrapper .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100; }
    .loader-wrapper .loader.loader-center {
      transform: translate(-50%, -50%); }
    .loader-wrapper .loader.loader-bottom {
      transform: translate(-50%, 0); }
    .loader-wrapper .loader.loader-top {
      transform: translate(-50%, -100%); }
  .loader-wrapper .loader-overlay {
    z-index: 11; }
    .loader-wrapper .loader-overlay-text {
      position: absolute;
      text-align: center;
      top: 34%;
      font-size: 12px;
      width: 100%;
      left: 0;
      padding: 0 24px; }

.loader {
  margin: 0 auto;
  width: 80px;
  display: block;
  white-space: nowrap; }
  .loader.loader-small {
    width: 24px;
    overflow: hidden; }
    .loader.loader-small span {
      width: 3px;
      height: 3px;
      margin: 0 3px; }
  .loader-fixed {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1072; }
  .loader span {
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    margin: 0 4px;
    display: inline-block;
    animation: loader-animation 0.6s infinite ease-in-out;
    color: #339bc0; }
    .loader span:nth-child(2) {
      animation-delay: 200ms; }
    .loader span:nth-child(3) {
      animation-delay: 300ms; }
    .loader span:nth-child(4) {
      animation-delay: 400ms; }
    .loader span:nth-child(5) {
      animation-delay: 500ms; }

.loader-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); }

.overlay-0 {
  opacity: 0; }

.overlay-65 {
  background-color: rgba(0, 0, 0, 0.65); }

.overlay-75 {
  background-color: rgba(0, 0, 0, 0.75); }

.overlay-light-bg {
  background-color: rgba(55, 71, 79, 0.7); }

.overlay-card-bg {
  background-color: rgba(29, 37, 46, 0.7); }

@keyframes loader-animation {
  0%,
  40%,
  100% {
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.4); }
  20% {
    transform: scale(1.2);
    background-color: currentColor; } }
