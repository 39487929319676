@import '../../../node_modules/@agile/react-components/src/scss/variables';

.notification {
  &-icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: rgba(216, 216, 216, 0.1);
    border: solid 1px rgba($white, 0.2);
    display: inline-block;
    vertical-align: top;
    margin: 0 12px 0 0;
    text-align: center;
    position: relative;
    float: left;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -12px;
    }
  }

  &-row {
    vertical-align: middle;
    font-size: 14px;
    position: relative;

    .card-toggle-wrappepr {
      cursor: pointer;
      position: relative;
      &:after {
        @include media-breakpoint-down(sm) {
          content: "";
          width: 100%;
          height: 100%;
          opacity: 0.6;
          background-color: rgba($black, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    &.open,
    &:hover {
      .notification-row-controls {
        visibility: visible;
        opacity: 1;
      }
      .card-toggle-wrappepr {
        &:after {
          @include media-breakpoint-down(sm) {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .notification-row-date {
        visibility: hidden;
      }
    }
    &.disabled-cursor {
      .text-white-lighter {
        color: #6c757d;
      }
      .notification-more {
        position: relative;
        color: #6c757d;
        .message {
          position: absolute;
          top: 20px;
          left: 0;
          width: 100%;
          z-index: 101;
          color: $white;
        }
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          opacity: 0.6;
          box-shadow: 0 2px 3px 0 rgba($black, 0.5);
          background-color: rgba($black, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 100;
        }
      }
    }
    &-controls {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      right: 0;
      top: 0;
      margin: 15px 20px;
      z-index: 1;
    }
    &.card.card-modern {
      border: 1px solid transparent;
      transition: border-color 0.2s ease-in-out;
      &:hover {
        border-color: $primary;
      }
    }
  }

  &-content {
    line-height: 38px;
    vertical-align: middle;
    overflow: hidden;
    .text {
      line-height: 1.5;
      display: inline-block;
    }
  }

  &-more {
    padding: 0 25px 14px;
  }
}

.card {
  &.card-modern {
    &.notification-row {
      min-height: auto;
      padding: 0;
      & > .card-body {
        .card-toggle-wrappepr {
          padding: 15px 24px 20px;
        }
      }
    }
  }
}

.text-lighten {
  opacity: 0.6;
}

.app-table {
  .notification-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
