// Container
.mr {
  &-container {
    &-sm {
      margin: 0 auto;
      width: 100%;
      padding: 18px 20px 28px;

      @include media-breakpoint-up(sm) {
        width: 560px;
      }
    }

    &-md {
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        max-width: 782px;
      }
    }

    &-md-960 {
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        max-width: 960px;
      }
    }

    &-lg {
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        max-width: 1430px;
      }
    }
  }
}

.feedback-right {
  .invalid-feedback {
    right: 0;
    width: auto;
  }
}

.link {
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

.download-button-wrapper {
  display: inline-block;
  min-height: 24px;
  vertical-align: top;
}

.btn-download {
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
    ;
  }
}

.filter-wrapper {
  min-width: 90px;
}

.text-white-lighter {
  color: rgba($white, 0.6);
}

.m-t-label {
  margin-top: 27px;
}

.mg-a {
  margin: 0 auto;
}

hr {
  border-top-color: rgba($white, 0.2);
}

.page-empty {
  margin: 0 auto;
  width: 100%;
  max-width: 752px;
  border-radius: 4px;
  background-color: rgba($white, 0.1);
  box-shadow: 0 2px 4px 0 rgba($black, 0.5);
  padding: 15px 30px 30px 30px;
  min-height: 80vh;
  color: $white;

  &-space {
    padding-top: 98px;
  }

  &-icon {
    margin: 96px 0 25px;
  }

  &-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 15px;
  }

  &-title-lg {
    font-size: 100px;
    line-height: 172px;
    margin: 0 0 17px;
    font-weight: 600;
    text-align: center;
    opacity: 0.2;
    text-shadow: 0 3px 5px rgba($black, 0.3);

    @include media-breakpoint-up(sm) {
      font-size: 144px;
    }
  }

  .page-msg {
    margin: 0 0 36px;
  }
}

.daterangepicker-wrapper,
.form-control-wrapper,
.minifuture-form .form-group {
  label {
    margin-bottom: 0px;
  }
  .form-control[readonly], .form-control-date-range input[readonly] {
    opacity: 1;
    background-color: transparent;
    border: 0px solid rgba(255, 255, 255, 0.3) !important;
    padding: 0px !important;
  }
  margin-bottom: 0.4rem;
}

.mf-price .form-control, .form-control-date-range input {
    font-size: 18px !important;
    margin-top: -4px;
    font-weight: bold;
}

.daterangepicker-wrapper,
.form-group {
  margin-bottom: 22px;

  &.view-lvrv {
    margin-bottom: 0.4rem;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 1rem;
  }
}

.form-group-mb-0 {
  .form-group {
    margin-bottom: 0;
  }
}

.modal-switcher {
  display: inline-block;
}

.divider-line {
  width: 1px;
  height: 40px;
  background-color: rgba($white, 0.2);
  display: inline-block;
  vertical-align: middle;
  line-height: 40px;
  margin: 0 15px;
}

.container-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 15px;
}

// Tour
.main-content {
  .joyride {
    &-beacon {
      display: none !important; //Hide beacon after click in overlfay
    }

    &-tooltip__main {
      padding-bottom: 0;
      padding-right: 0;
      overflow: hidden;
      font-size: 14px;
    }

    &-tooltip.right .joyride-tooltip__triangle {
      height: 20px;
      left: -10px;
      background-size: 100% 100%;
      width: 10px;
    }

    &-tooltip.left .joyride-tooltip__triangle {
      height: 20px;
      right: -10px;
      background-size: 100% 100%;
      width: 10px;
    }

    &-hole {
      box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
      border: solid 2px #f1f1f1;
      border-radius: 8px;
    }

    &-tooltip__close {
      display: none !important;
    }

    &-overlay {
      z-index: 1040; //Bigger than bootstrap modal z-index
    }

    &-tooltip {
      z-index: 1041;
      padding: 14px 20px 18px 35px;

      &-content {
        float: left;
        width: 285px;
        padding-right: 15px;
      }

      &-footer {
        float: left;
        width: 110px;

        .btn {
          &:focus {
            box-shadow: none;
          }
        }

        .btn.btn-link {
          color: rgba(#000000, 0.5);

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

.mr-container-md {
  .header-username {
    max-width: none;
  }
}

.header-username {
  display: inline-block;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media-breakpoint-up(sm) {
    max-width: none;
  }
}

.header-dropdown {
  .dropdown-menu {
    // margin: -4px 0;
    max-width: 160px;
  }

  .dropdown-toggle {
    position: relative;
    padding-right: 25px;

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      margin: 24px 11px;
    }
  }
}


.report-block {
  background-color: $white;
  height: 830px;
}

.block-circle {
  display: block;
  border-radius: 50%;
  background-color: rgba($white, 0.1);
  margin: 0 auto;
  text-align: center;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.s {
  &-40 {
    width: 40px;
    height: 40px;
    line-height: 40px;

    svg {
      font-size: 19px;
    }
  }

  &-80 {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }

  &-54 {
    width: 54px;
    height: 54px;
    line-height: 54px;
  }
}

.sign-up-message {
  margin: 0 -15px 0 0;
  font-size: 11px;

  @include media-breakpoint-up(sm) {
    margin: 2px 0;
  }
}

.price-solve-for-wrapper {
  min-height: 81px;
}

.tour-barrier-type {
  min-height: 81px;
}

.price-dynamic-form-wrapper {
  min-height: 79px;

  .form-control {
    padding-right: .4rem;
  }
}

.dashboard {
  &-container {
    min-height: 80vh;
    width: 100%;
  }
}

.price-form-fields {
  .form-group {
    min-height: 63px;
  }
}

.w-144 {
  width: 144px;
}

.output-message {
  opacity: 0.8;
  font-size: 12px;
  display: inline-block;
  margin: 3px -10px 0 0;
}

dl {
  dt {
    font-weight: normal;
    color: rgba($white, 0.8);
    font-size: 12px;
  }
}


.row {
  &.row-size-sm {
    margin-left: -10px;
    margin-right: -10px;

    &>[class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &.row-size-xs {
    margin-left: -5px;
    margin-right: -5px;

    &>[class*="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.container {
  &-lg {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 967px) {
      max-width: 100%;
    }

    @media (min-width: 1040px) {
      max-width: 1040px;
    }

    @media (min-width: 1200px) {
      max-width: 1180px;
    }

    @media (min-width: 1400px) {
      max-width: 1380px;
    }
  }
}


.word-wrap {
  word-wrap: break-word;
}

.output-message {
  opacity: 0.8;
  font-size: 12px;
  display: inline-block;
  margin: 3px -10px 0 0;
}

.w-144 {
  width: 144px;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.text-label {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
  margin-bottom: 4px;
}

.border-bottom {
  border-bottom-color: rgba($white, 0.32);
}

.form-card-minifuture-fx {
  background-color: rgba(255, 255, 255, 0.01) !important;
  box-shadow: 0 0px 2px 0 #ffffff !important;
}

.form-card-minifuture,
.form-card-vanilla,
.form-card-swap {
  .invalid-feedback {
    @include media-breakpoint-up(lg) {
      position: absolute;
      white-space: nowrap;
    }
  }
}

.card-minifuture,
.card-vanilla,
.card-swap {
  transition: transform 180ms ease-in, top 215ms ease-in, left 215ms ease-in;
}

.bg-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.form-control.is-invalid {
  background-image: none !important;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

body {
  .react-datepicker-popper {
    z-index: 10;
  }
}

.simple-list {
  list-style: none;
  padding: 0;
  margin: 0 0 30px;

  li {
    @extend .clearfix;
    padding: 0;
    margin: 0 0 8px;
  }

  .number {
    float: left;
    width: 40px;
  }

  .text {
    width: calc(100% - 40px);
    float: left;
  }
}

#chat-widget-container {
  z-index: 11 !important;
}
