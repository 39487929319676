@import '../../../node_modules/@agile/react-components/src/scss/variables';;

.text-gray {
  color: $border-color;
}

$card-pricing-history-row-margin: 8px;

.pricing-history {
  position: relative;

  &-title {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;

    @include media-breakpoint-up(sm) {
      margin: 2px 48px 2px 0;
    }

    &:hover {
      .icon {
        transform: translate3d(-3px, 0, 0);
      }
    }

    .icon {
      cursor: pointer;
      margin: -5px 9px -5px 0;
      transition: transform 0.15s ease-in-out;
    }
  }

  &-header {
    font-weight: 500;
    padding: 0 $card-pricing-history-row-margin;
    border-bottom: 1px solid rgba($white, 0.2);
    position: relative;
    z-index: 2;
    color: rgba($white, 0.52);

    &.app-table-header {
      line-height: 38px;
      min-height: 38px;
    }

    .app-table-col {
      min-height: 38px;
    }
  }

  &-list {
    background-color: rgb(29, 37, 46);
    position: relative;
    min-height: 100%;
    padding: 24px 15px 30px;

    @include media-breakpoint-up(sm) {
      padding: 36px 48px 36px 16px;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }

    &-empty {
      text-align: center;
      font-size: 13px;
      color: $white;
      text-align: center;
      margin: 45px 0;

      .icon {
        margin: 0 0 25px;
      }

      .title {
        padding: 0;
        margin: 0 0 8px;
        font-size: 18px;
        font-weight: 600;
      }

      .msg {
        margin: 0 0 30px;
      }
    }

    // Dynamic table columns auto size
    .app-table-header {
      @include media-breakpoint-up(sm) {
        display: flex !important;
      }
    }

    .app-table-row {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .app-table-col {
      @include media-breakpoint-up(sm) {
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }

  &-content {
    position: relative;
    min-height: 80vh;
    width: 100%;
    z-index: 1;

    .row {
      margin-left: -$card-pricing-history-row-margin;
      margin-right: -$card-pricing-history-row-margin;
    }

    .app-table-col {
      @include media-breakpoint-up(sm) {
        font-size: 12px;
      }
    }

    .app-table-header {
      .app-table-col {
        @include media-breakpoint-up(sm) {
          font-size: 11px;
        }
      }
    }

    .app-table-col,
    [class*="col-"] {
      padding: 0 4px;
    }
  }

  &-scroller {
    @include media-breakpoint-up(sm) {
      overflow-y: auto;
      position: absolute;
      bottom: 36px;
      width: auto;
      left: 0;
      position: absolute;
      top: 38px; //Table header height
      right: 0;
    }

    .card-pricing-history {
      &:first-child {
        border-top-color: transparent;
      }
    }
  }

  &-list-wrapper {
    position: fixed;
    z-index: 1100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint-up(sm) {
      overflow: hidden;
      left: 50px;
    }

    @media (min-width: 1900px) {
      left: 111px;
    }
  }

  &-has-more {
    .pricing-history-scroller {
      @include media-breakpoint-up(sm) {
        right: -16px;
      }
    }
  }
}

.pricing-history-animation {
  &-enter {
    .pricing-history-list-wrapper {
      opacity: 0;
      transform: translate3d(80%, 0, 0);
    }

    &-active {
      .pricing-history-list-wrapper {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        transition: {
          property: opacity, transform;
          duration: 0.3s;
          timing-function: ease-in-out;
        }
      }
    }
  }

  &-exit {
    .pricing-history-list-wrapper {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &-active {
      .pricing-history-list-wrapper {
        opacity: 0;
        transform: translate3d(80%, 0, 0);

        transition: {
          property: opacity, transform;
          duration: 0.3s;
          timing-function: ease-in-out;
        }
      }
    }
  }
}

// Table Pricing History sizes
.pricing-history-list {
  &.columns-diplay-all {
    .app-table-row {
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    //All columns is displayed
    .app-table-header {
      @include media-breakpoint-up(sm) {
        display: block !important;
      }
    }

    .size {
      &-updatedAt {
        @include colSize(percentage(72/1000));
      }

      &-time {
        @include colSize(percentage(54/1000));
      }

      &-structure {
        @include colSize(percentage(54/1000));
      }

      &-type {
        @include colSize(percentage(69/1000));
      }

      &-commodityContract {
        @include colSize(percentage(80/1000));
      }

      &-contractExpiry {
        @include colSize(percentage(78/1000));
      }

      &-quotedCurrency {
        @include colSize(percentage(49/1000));
      }

      &-remainderLevel,
      &-remainderFeature {
        @include colSize(percentage(40/1000));
      }

      &-optionExpiryDate {
        @include colSize(percentage(50/1000));
      }

      &-referencePrice {
        @include colSize(percentage(54/1000));
      }

      &-strikeLevel {
        @include colSize(percentage(54/1000));
      }

      &-barrierType {
        @include colSize(percentage(54/1000));
      }

      &-barrierLevel {
        @include colSize(percentage(54/1000));
      }

      &-price {
        @include colSize(percentage(54/1000));
      }

      &-leverageStyle {
        @include colSize(percentage(40/1000))
      }

      &-accumulationStyle {
        @include colSize(percentage(50/1000))
      }

      &-leverage {
        @include colSize(percentage(45/1000))
      }
    }
  }
}

.control-columns {
  z-index: 100;
  position: relative;
}

.card-pricing-history {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    padding: 7px 8px;
  }

  transition: {
    property: border-color, background-color;
    duration: 0.15s;
    timing-function: ease-in-out;
  }

  .card-pricing-history-action {
    text-align: right;
    visibility: hidden;
    opacity: 0;

    transition: {
      property: opacity;
      duration: 0.15s;
      timing-function: ease-in-out;
    }

    position: absolute;
    right: 0;
    top: 0;
    padding: 2px 32px;
    width: 426px;
    height: 100%;
    background-image: linear-gradient(to left, #11171c, #11171c 35%, rgba(17, 23, 28, 0));
  }

  &:hover:not(.disabled) {
    .card-pricing-history-action {
      @include media-breakpoint-up(sm) {
        opacity: 1;
        visibility: visible;
      }
    }

    &+.card-pricing-history {
      @include media-breakpoint-up(sm) {
        border-color: transparent;
        padding: 7px 8px;
      }
    }
  }

  &:active:not(.disabled) {
    @include media-breakpoint-up(sm) {
      background-color: transparentize($color: $primary, $amount: 0.15);
    }
  }

  &.pricinig-history-active {
    animation: bump-primary 3s;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.trail-appear-list {
  overflow: hidden;

  .trail-appear {

    &-enter,
    &-appear {
      transition: transform ease-in 500ms, opacity ease-in 350ms;
      transform: translateX(-100%);
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(10n+#{$i}) {
          transition-delay: $i*70ms;
        }
      }

      &-active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.pricing-history {
  &-wrapper {
    .pricing-history-list-wrapper {
      top: $header-h-logged;
      z-index: #{$z-index-header - 1};

      &>div {
        height: 100%;
      }
    }

    .modal-backdrop {
      top: $header-h-logged;
      z-index: #{$z-index-header - 2};
    }
  }

  &-btn {
    width: 56px;
    height: 56px;
    background-image: linear-gradient(225deg, #337bc0, #33a3c0);
    box-shadow: 0 3px 5px 0 rgba(0, 143, 189, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.5);
    position: fixed;
    border-radius: 50%;
    z-index: 999;
    bottom: 90px;
    right: 20px;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
      right: 35px;
      bottom: 40px;
    }

    &:hover {
      svg {
        transform: rotate(-360deg);
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 26px;
      height: 26px;
      margin: -13px;
      transform: rotate(0deg);
      display: block;
      transition: transform ease-in 0.3s;
    }
  }
}

@include media-breakpoint-up(sm) {
  .card-pricing-history {
    .card-pricing-history-border {
      border-right: 1px solid $border-color;
    }
  }
}

.background-transparent {
  background-color: transparent;
}

@keyframes bump-primary {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: transparentize($color: $primary, $amount: 0.15);
  }

  100% {
    background-color: transparent;
  }
}

.order {
  &-asc {
    .active {
      span {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.app-table-col-align {
  line-height: 32px;
  min-height: 38px;

  &>.text {
    position: relative;
    padding: 0 6px 0 0;
    max-width: 100%;
    display: inline-block;
    vertical-align: bottom;
    line-height: 1.5;
  }
}

.pricing-history-animation-enter-done {
  .loader-fixed {
    display: none !important;
  }
}
