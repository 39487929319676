@import '../../../../../node_modules/@agile/react-components/src/scss/variables';
@import '../../../../../node_modules/@agile/react-components/src/scss/components/inputs';

.SizePerSettlementInput {
  .dropdown {
    .invalid-feedback {
      display: block;
    }

    .btn-secondary:focus {
      background: none;
    }

    &.show {
      .dropdown-menu {
        max-height: 30vh;
        min-width: 25vh;
        background-color: $select-bg;
        box-shadow: 0 3px 5px 0 rgba($black, 0.3);
        border-radius: 4px;
        border: 0;
        margin: 1px 0;
      }

      .Select-arrow-zone {
        transform: rotate(180deg);
      }

      .dropdown-toggle {
        background: none;
      }

      .lable {
        .form-control {
          border-color: $primary;
          background-color: $select-option-focused-bg;
        }
      }


      .invalid-feedback {
        display: none;
      }

      &>.btn-secondary.dropdown-toggle {
        background: none;
      }

    }

    .Select-arrow {
      padding: 0;
      background: transparent;
      border: 0;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      margin: -6px -4px;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;

      border: {
        color: $white;
        width: 0 0 2px 2px;
        style: solid;
      }

      &-zone {
        width: 45px;
        position: absolute;
        right: 0;
        top: 18px;

        transition: opacity 0.15s ease-out, -webkit-transform 0.15s linear;
        transition: opacity 0.15s ease-out, transform 0.15s linear;
        transition: opacity 0.15s ease-out, transform 0.15s linear, -webkit-transform 0.15s linear;
        opacity: 1;

        @include media-breakpoint-up(sm) {
          width: 25px;
        }
      }
    }

    &-toggle {
      border: none;
      padding: 0;
      box-shadow: none !important;
      background: none;
      position: absolute;
      top: 0;
      min-height: 36px;
      width: 100%;

      &::after {
        display: none;
      }

      .form-control {
        cursor: pointer;
        opacity: 1;
        font-size: 14px;
        line-height: 1.6;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.6);
        box-shadow: none;
        min-height: 36px;
        transition-property: border-color, background-color, background-image;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
      }
    }

    &-divider {
      border-top: 1px solid rgba(255, 255, 255, 0.32);
      margin: 0.5rem 1rem;
    }

    &-item-text,
    &-header {
      padding: 0.25rem 1.5rem;
      cursor: pointer;

      .col {
        color: #ffffff;
        box-sizing: border-box;
        line-height: 34px;
        font-size: 14px;
      }
    }

    &-item-text:hover {
      background-color: $select-option-focused-bg;
    }

    .form-group {
      margin: 0;

      &-number {
        .form-control {
          padding-right: 5px;
        }
      }
    }
  }
}
