@import '../../../node_modules/@agile/react-components/src/scss/variables';

.recharts {
    &-layer.recharts-cartesian-axis-tick tspan {
        fill: $card-title-color;
    }
    &-xAxis {
        font-size: 12px;
    }
}

.recharts-responsive-container {
    margin: 2px 0;
}

.product-chart-tooltip {
    background-color: rgba(0, 0, 0, 0.95);
    padding: 10px;
}

.chart-zoom {
    margin: -5px 0;
}

.modal-dialog {
    &.modal-chart {
        max-width: 1136px;
    }
}
